import type { Contact } from "~/types/drupal_jsonapi";
import { For, Show } from "solid-js";
import ContactPerson from "~/components/PressReleases/Components/ContactPerson";

import "./ContactPart.css";

export default function ContactPartMain(props: {
  contacts_main_altarea: Contact[];
  contacts_main_agence_shan: Contact[];
}) {
  return (
    <>
      <section class="contact-part" data-test="contacts">
        <div class="inner">
          <h2>Contacts presse</h2>
          <div class="the-contacts">
            <Show when={props.contacts_main_altarea}>
              <div class="contact-group" data-test="altarea">
                <h3>Altarea</h3>
                <div class="contact-list" data-test="contacts-list">
                  <For each={props.contacts_main_altarea}>
                    {(contact) => {
                      return (
                        <>
                          <ContactPerson contact={contact} withoutCompany />
                        </>
                      );
                    }}
                  </For>
                </div>
              </div>
            </Show>
            <Show when={props.contacts_main_agence_shan}>
              <div class="contact-group" data-test="agency">
                <h3>Agence Shan</h3>
                <div class="contact-list" data-test="contacts-list">
                  <For each={props.contacts_main_agence_shan}>
                    {(contact) => {
                      return (
                        <>
                          <ContactPerson contact={contact} withoutCompany />
                        </>
                      );
                    }}
                  </For>
                </div>
              </div>
            </Show>
          </div>
        </div>
      </section>
    </>
  );
}
