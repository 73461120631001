import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  type Setter,
  Show,
} from "solid-js";
import { urlRs } from "~/utils/url";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useSearchParams } from "@solidjs/router";

import type { ItemReference } from "~/types/drupal_jsonapi";

import "./FiltersBox.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

export default function FiltersBox(props: {
  items: ItemReference & { year: number }[];
}) {
  const [viewportSizeProvider] = useViewportSizeContext();

  const dateArray = createMemo(() => {
    const finalArray: number[] = [];
    props.items.forEach((item) => {
      if (!finalArray.includes(item.year)) {
        finalArray.push(item.year);
      }
    });
    return finalArray.sort().reverse();
  });

  const [actualYear, setActualYear] = createSignal();
  const [searchParams] = useSearchParams();

  setActualYear(searchParams.year);

  return (
    <>
      <div class="filters-box" data-test="filters">
        <span class="legend">Filtrer :</span>
        <div class="filters-list">
          <Show
            when={!viewportSizeProvider.viewPortIsLessThan768}
            fallback={
              <FormInputSelectFilter
                datas={dateArray()}
                parentSetter={setActualYear}
              />
            }
          >
            <ul class="filters">
              <For each={dateArray()}>
                {(date: number) => (
                  <li>
                    <a
                      href={
                        actualYear() === date.toString()
                          ? urlRs("press_releases", "/espace-presse/")
                          : urlRs(
                              "press_releases",
                              `/espace-presse/?year=${date}`,
                            )
                      }
                      classList={{ active: actualYear() === date.toString() }}
                      data-test={`date-${date}`}
                    >
                      {date}
                    </a>
                  </li>
                )}
              </For>
            </ul>
          </Show>
        </div>
      </div>
    </>
  );
}

function FormInputSelectFilter(props: {
  datas: number[];
  parentSetter: Setter<unknown>;
}) {
  const settings = useDrupalSettingsContext();

  const [inputHasContent, setInputHasContent] = createSignal(false);
  const [menuDialogIsActive, setMenuDialogIsActive] = createSignal(false);

  const [dateIndex, setDateIndex] = createSignal<number | undefined>();

  createEffect(() => {
    if (typeof dateIndex() !== "undefined" && props.datas.at(dateIndex()!)) {
      setInputHasContent(true);
    } else {
      setInputHasContent(false);
    }
  });

  const [searchParams] = useSearchParams();

  const inputValue = createMemo(() => {
    if (typeof dateIndex() !== "undefined" && props.datas.at(dateIndex()!)) {
      return props.datas.at(dateIndex()!);
    } else if (searchParams.year) {
      return searchParams.year.toString();
    }
    return undefined;
  });

  createEffect(() => {
    if (inputValue() !== undefined) {
      setInputHasContent(true);
    }
  });

  function setActiveDate(index: number) {
    setMenuDialogIsActive(false);
    setDateIndex(index);

    document.location.href = urlRs(
      "press_release",
      `/espace-presse/?year=${props.datas.at(index)!.toString()}`,
      settings,
    );
  }

  const [inputIsFocus, setInputIsFocus] = createSignal(false);

  return (
    <>
      <div
        class="form-control"
        classList={{
          "is-focus": inputIsFocus(),
          "has-content": inputHasContent(),
        }}
      >
        <div class="form-slot form-select">
          <label for="filter-select">Année</label>
          <input
            id="filter-select"
            name="filter_select"
            type="text"
            value={inputValue() ? inputValue() : ""}
            readonly
            onFocusIn={() => {
              setInputIsFocus(true);
              setMenuDialogIsActive(true);
            }}
            onBlur={() => {
              setInputIsFocus(false);
            }}
            data-test="dates"
          />
          <i aria-hidden="true" class="cog-icon">
            <IconExpandMore />
          </i>
          <div class="menu-dialog" classList={{ active: menuDialogIsActive() }}>
            <div
              class="dialog-overlay"
              onClick={() => setMenuDialogIsActive(false)}
            />
            <ul class="list" data-test="menu-items">
              <li
                onClick={() => {
                  setMenuDialogIsActive(false);
                  setDateIndex(undefined);

                  document.location.href = urlRs(
                    "press_release",
                    `/espace-presse/`,
                    settings,
                  );
                }}
                classList={{
                  active: undefined === dateIndex(),
                }}
                data-test="date-all"
              >
                Tout
              </li>
              <For each={props.datas}>
                {(date: number, index) => (
                  <>
                    <li
                      onClick={() => setActiveDate(index())}
                      classList={{
                        active: index() === dateIndex(),
                      }}
                      data-test={`date-${date}`}
                    >
                      {date}
                    </li>
                  </>
                )}
              </For>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
