import { ErrorBoundary, Show, Suspense, createResource } from "solid-js";
import { getPressRelease } from "~/lib/fetcher";
import CardShell from "~/components/Cards/CardShell";
import type { ItemReference } from "~/types/drupal_jsonapi";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";

import "./PressReleaseCard.css";

import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";
import { formatDateUStoFrenchShortString } from "~/utils/format";

export default function PressReleaseCard(props: {
  nid: number;
  initialDeferStream?: boolean;
  item: ItemReference;
}) {
  const [pressRelease] = createResource(() => props.nid + "", getPressRelease, {
    deferStream: props.initialDeferStream,
  });

  return (
    <>
      <ErrorBoundary
        fallback={(err, reset) => (
          <p style="border: 1px solid red" onClick={reset}>
            Error: {err.toString()}
          </p>
        )}
      >
        <Suspense
          fallback={<CardShell title={props.item.title} url={props.item.url} />}
        >
          <Show when={pressRelease()}>
            <article class="node-press-release-card" data-test="card-release">
              <picture class="visual">
                <Show
                  when={pressRelease()!.field_image}
                  fallback={
                    <img
                      src={imgProxy(
                        "/images/default-pressrelease-mini-visual.png",
                        "size:700:300/resizing_type:fill",
                      )}
                      alt=""
                      height="160"
                      width="375"
                      loading="lazy"
                    />
                  }
                >
                  <img
                    fetchpriority="high"
                    src={imgProxy(
                      pressRelease()!.field_image.uri.url,
                      `size:700:300/resizing_type:fill`,
                    )}
                    alt={pressRelease()!.field_image.meta?.alt}
                    height="160"
                    width="375"
                    loading="lazy"
                  />
                </Show>
              </picture>
              <div class="content-press-release">
                <span class="submitted" data-test="date">
                  {formatDateUStoFrenchShortString(pressRelease()!.field_date)}
                </span>
                <h3>{pressRelease()!.title}</h3>
              </div>
              <a
                href={urlRs("press_releases", pressRelease()!.path.alias)}
                title={`Lire la suite de ${pressRelease()!.title}`}
                data-test="content-link"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconKeyboardArrowRight />
                </i>
                <span class="legend">Lire la suite</span>
              </a>
            </article>
          </Show>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
